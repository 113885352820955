import React from "react";
import { Link } from "gatsby";

import { StyledLink } from "baseui/link";
import { Paragraph1, Label1 } from "baseui/typography";

import Page from "../../components/internal-page";

const Collateral = () => {
  return (
    <Page
      title="Информация для залоговых займов"
      path="/collateral"
      parent="collateral"
    >
      <Label1>Оценка залогового имущества</Label1>
      <Paragraph1>
        Согласно Правилам Фонда займы на сумму свыше 500 тыс.руб. должны иметь
        основное обеспечение в форме залога и (или) банковской гарантии в
        размере фактически полученной суммы микрозайма и уплаты процентов на
        него. Расходы по оформлению документов по обеспечению микрозаймов
        производятся Заявителем за счет собственных средств. Залоговое имущество
        должно пройти процедуру оценки и быть застраховано за счет средств
        Заемщика (Залогодателя).
      </Paragraph1>
      <Paragraph1>
        Фонд "МКК Развитие" заключил партнерские соглашения на оценку залогового
        имущества с{" "}
        <StyledLink to="/fund/partners#valuation" $as={Link}>
          оценщиками
        </StyledLink>
        .
      </Paragraph1>
      <Label1>Страхование залогового имущества</Label1>
      <Paragraph1>
        Согласно Правилам Фонда займы на сумму свыше 500 тыс. руб. должны иметь
        основное обеспечение в форме залога и (или) банковской гарантии в
        размере фактически полученной суммы микрозайма и уплаты процентов на
        него. Расходы по оформлению документов по обеспечению микрозаймов
        производятся Заявителем за счет собственных средств. Залоговое имущество
        должно пройти процедуру оценки и быть застраховано за счет средств
        Заемщика (Залогодателя). Имущество, принимаемое в залог в качестве
        обеспечения исполнения обязательств Заёмщика по договору займа, должно
        быть застраховано от рисков утраты и повреждения. Выгодоприобретателем
        по договору страхования должен быть указан Залогодержатель (Фонд).
      </Paragraph1>
      <Paragraph1>
        Фонд "МКК Развитие" заключил партнерские соглашения на страхование
        залогового имущества со{" "}
        <StyledLink to="/fund/partners#insurance" $as={Link}>
          страховыми компаниями
        </StyledLink>
        .
      </Paragraph1>
    </Page>
  );
};

export default Collateral;
